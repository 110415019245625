body {
  color: #e6aebb;
  background: #f7e6ea;
  margin: 0;
  padding: 0;
  font-family: lato, sans-serif;
}

* {
  box-sizing: border-box;
}

.top {
  width: 100%;
  height: 550px;
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 500px;
  overflow: hidden;
}

input {
  width: 300px;
  outline: none;
  padding: 15px;
  font-size: 14pt;
}

input[type="submit"] {
  color: #fff;
  cursor: pointer;
  background: #e6aebb;
  border: 0;
  border-radius: 5px;
  font-size: 16pt;
}

input[type="submit"]:hover {
  color: #373a47;
}

#banner img {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  color: #e6aebb;
  z-index: 101;
  width: 100%;
  background-color: #0000;
  padding: 0 30px;
  font-style: normal;
  font-weight: 700;
  position: relative;
}

.header img {
  margin: 20px 0 0;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    text-align: left;
    display: block;
  }
}

.bm-burger-button {
  width: 36px;
  height: 30px;
  position: fixed;
  top: 36px;
  right: 36px;
}

.bm-burger-bars, .bm-burger-bars-hover {
  background: #fff;
}

.bm-cross-button {
  height: 40px;
  width: 40px;
}

.bm-cross {
  background: #e6aebb;
}

.bm-cross:hover {
  background: #f0dadf;
}

.bm-menu-wrap {
  height: 100%;
  position: fixed;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #f0dadf;
}

.bm-item-list a {
  color: #f0dadf;
  text-decoration: none;
}

.bm-item-list a:hover {
  color: #e6aebb;
}

.bm-item-list a span {
  margin-left: 10px;
}

.bm-item {
  padding: 1em;
  display: inline-block;
}

.bm-overlay {
  background: #0000004d;
  margin: 0;
}

.overlay {
  width: 400px;
  height: 400px;
  z-index: 2;
  color: #373a47;
  background: #fafafa;
  padding: 0 20px 20px;
  position: absolute;
  bottom: 0;
  left: calc(50% + 100px);
}

.overlay ul {
  padding-inline-start: 5px;
  list-style: none;
  display: block;
}

.overlay ul li {
  padding: 0 10px 14px;
}

.overlay .row {
  padding-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .overlay {
    left: calc(50% - 200px);
  }
}

.review {
  min-height: 220px;
  background: #e6aebb;
  border-radius: 10px;
  padding: 20px;
  font-style: italic;
  box-shadow: 10px 13px 18px -11px #000000bf;
}

.reviewer {
  padding-top: 20px;
  padding-left: 10px;
  font-weight: bold;
}

.sections {
  color: #373a47;
  padding-top: 550px;
  font-size: 16pt;
}

.sections > div {
  justify-content: center;
  padding: 25px;
  display: flex;
}

.sections > div:nth-of-type(2n) {
  background: #fafafa;
}

.sections > div > .text {
  max-width: 400px;
  margin: 30px;
  padding-top: 40px;
}

@media screen and (max-width: 900px) {
  .sections > div {
    flex-wrap: wrap;
  }
}

/*# sourceMappingURL=index.845f2e2f.css.map */
