.review {
    font-style: italic;
    min-height: 220px;
    background: #E6AEBB;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 10px 13px 18px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 13px 18px -11px rgba(0,0,0,0.75);
    box-shadow: 10px 13px 18px -11px rgba(0,0,0,0.75);
}

.reviewer {
    padding-top: 20px;
    padding-left: 10px;
    font-weight: bold;
}