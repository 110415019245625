.header {
    background-color: transparent;
    color: #E6AEBB;
    font-style: normal;
    font-weight: 700;
    z-index: 101;
    width: 100%;
    padding: 0 30px;
    position: relative;
}

.header img {
  margin: 20px 0 0 0;
}


@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
}