.overlay {
    width: 400px;
    height: 400px;
    position: absolute;
    z-index: 2;
    background: #fafafa;
    bottom: 0;
    left: calc(50% + 100px);
    padding: 0 20px 20px 20px;
    color: #373a47;
}

.overlay ul {
    list-style: none;
    display: block;
    padding-inline-start: 5px;
}

.overlay ul li {
    padding: 0 10px 14px 10px;
}

.overlay .row {
    padding-bottom: 10px;
}

@media only screen and (max-width: 900px) {
    .overlay {
        left: calc(50% - 200px);
    }
}