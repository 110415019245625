.sections {
    padding-top: 550px;
    color: #373a47;
    font-size: 16pt;
}

.sections > div {
    display: flex;
    justify-content: center;
    padding: 25px;
}

.sections > div:nth-of-type(even) {
    background: #fafafa;
}

.sections > div > .text {
    max-width: 400px;
    margin: 30px;
    padding-top: 40px;
}

@media screen and (max-width: 900px) {
    .sections > div {
        flex-wrap: wrap;
    }
}