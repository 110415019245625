body {
    background: #f7e6ea;
    color: #E6AEBB;
    padding: 0;
    margin: 0;
    font-family: lato, sans-serif;
}

* {
    box-sizing: border-box;
  }

.top {
  width: 100%;
  height: 550px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 500px;
  z-index: 0;
}

input {
  width: 300px;
  padding: 15px;
  font-size: 14pt;
  outline: none;
}

input[type=submit] {
  background: #E6AEBB;
  color: #fff;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  font-size: 16pt;
}

input[type=submit]:hover {
  color: #373a47;
}